import type { Activity, Winner } from '~/types/activity'

export const useActivityStore = defineStore('activityStore', () => {
  const activities = ref<Activity[] | null>(null)
  const count = ref(0)
  const winners = ref<Winner[] | null>(null)

  return {
    activities,
    count,
    winners,
  }
})
